<div [class]="getClass()">
  <button mat-flat-button (click)="openPicker()" #colorPickerButton class="color-picker__button">
    <span class="color-picker__toggle"
            [class.color-picker__toggle--as-line]="toggleFormat === 'line'"
            [class.color-picker__toggle--as-text]="toggleFormat === 'text'"
            [class.color-picker__toggle--as-polygon]="toggleFormat === 'polygon'"
            [class.color-picker__color-transparent]="!color"
            [style.backgroundColor]="color"
            mat-icon-button>
      <mat-icon *ngIf="toggleFormat === 'text'" [style.color]="color">format_size</mat-icon>
      <span *ngIf="toggleFormat === 'line'" [style.backgroundColor]="color"></span>
    </span>
    <mat-icon svgIcon="chevron_bottom" class="color-picker__chevron"></mat-icon>
  </button>
</div>

<ng-template #colorPickerContent>
  <div class="color-picker__picker mat-elevation-z4">

    <div class="color-picker__colors">

      <div *ngFor="let pickerColor of getPickerColors()"
           class="color-picker__color"
           (click)="selectColor(pickerColor)"
           (touchend)="selectColor(pickerColor)"
           [style.backgroundColor]="pickerColor"
           [class.color-picker__color-transparent]="!pickerColor"
           [class.mat-elevation-z4]="pickerColor === color"></div>
    </div>

    <div class="form-field-wrap">

      <mat-form-field appearance="outline" color="primary" subscriptSizing="fixed">
        <mat-label i18n>Color code</mat-label>
        <input matInput [formControl]="formControl">
        <mat-error *ngIf="hasError() && isRequired()" i18n>
          Color is required
        </mat-error>
        <mat-error *ngIf="hasInvalidColorError()">
          {{ getInvalidColorMessage() }}
        </mat-error>
      </mat-form-field>

    </div>

  </div>
</ng-template>
