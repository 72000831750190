<div class="login-page-wrapper">
  <tm-login-form [login$]="login$"
                 [loginConfiguration]="loginConfiguration$ | async"
                 [redirectUrl]="routeBeforeLogin$ | async"
                 [isViewer]="true"
                 (loggedIn)="loggedIn($event)"
                 [insufficientRightsErrorMessage]="insufficientRightsMessage$ | async"
                 loginErrorMessage="Login failed, please try again"
                 i18n-loginErrorMessage></tm-login-form>
</div>
