<div [formGroup]="attributeFilterForm">

  <mat-form-field appearance="outline">
    <mat-label i18n>Condition</mat-label>
    <mat-select i18n-aria-label
                aria-label="Select condition"
                formControlName="condition"
                panelClass="attribute-filter-condition-list-panel">
      <mat-option *ngFor="let attr of filteredConditions" [value]="attr.condition">{{attr.label}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" *ngIf="showValueInput()">
    <mat-label>
      <ng-container *ngIf="!showValueBetweenInput()" i18n>Value</ng-container>
      <ng-container *ngIf="showValueBetweenInput()" i18n>From</ng-container>
    </mat-label>
    <input type="text" matInput formControlName="value" />
  </mat-form-field>

  <mat-form-field appearance="outline" *ngIf="showValueBetweenInput()">
    <mat-label i18n>Until</mat-label>
    <input type="text" matInput formControlName="value2" />
  </mat-form-field>

  <mat-form-field appearance="outline" *ngIf="showDateInput()">
    <mat-label>
      <ng-container *ngIf="!showDateUntilInput()" i18n>Value</ng-container>
      <ng-container *ngIf="showDateUntilInput()" i18n>From</ng-container>
    </mat-label>
    <input matInput [matDatepicker]="dp" formControlName="value" />
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline" *ngIf="showDateUntilInput()">
    <mat-label i18n>Until</mat-label>
    <input matInput [matDatepicker]="dp2" formControlName="value2" />
    <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
    <mat-datepicker #dp2></mat-datepicker>
  </mat-form-field>

  <div *ngIf="showCaseSensitiveInput()" class="checkbox-margin">
    <mat-checkbox i18n formControlName="caseSensitive">
      Case sensitive
    </mat-checkbox>
  </div>

  <div *ngIf="showInvertConditionInput()">
    <mat-checkbox i18n formControlName="invertCondition">
      Invert condition
    </mat-checkbox>
  </div>

  <mat-checkbox *ngIf="!loadingUniqueValues && showUniqueValuesInput()"
                [checked]="allUniqueValuesSelected"
                [indeterminate]="someUniqueValuesSelected"
                (change)="toggleAllUniqueValues()">
    <span *ngIf="allUniqueValuesSelected" i18n>Deselect all values</span>
    <span *ngIf="!allUniqueValuesSelected" i18n>Select all values</span>
  </mat-checkbox>

  <div class="unique-values-list" *ngIf="showUniqueValuesInput()">
    <mat-spinner mode="indeterminate" diameter="20" *ngIf="loadingUniqueValues"></mat-spinner>
    <ng-container *ngFor="let uniqueValue of uniqueValues; let i = index; trackBy: trackByIndex">
      <mat-checkbox [checked]="uniqueValue.selected" (change)="toggleUniqueValue(uniqueValue.value)">{{uniqueValue.value}}</mat-checkbox>
    </ng-container>
  </div>

</div>
