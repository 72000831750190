<tm-dialog [open]="dialogOpen$ | async"
           [openFromRight]="true"
           [widthMargin]="panelWidthMargin"
           class="edit-dialog"
           (closeDialog)="closeDialog()"
           [allowCollapse]="true"
           [collapsed]="dialogCollapsed$ | async"
           (expandCollapseDialog)="expandCollapseDialog()"
           dialogTitle="Edit"
           i18n-dialogTitle>
  <div class="edit-form" *ngIf="currentFeature$ | async as currentFeature">
    <ng-container *ngIf="layerDetails$ | async as layerDetails">
      <div class="content">
        <h2>{{layerDetails.layer.title}}</h2>
        <tm-edit-form [feature]="{ feature: currentFeature.feature, details: layerDetails.details, columnMetadata: currentFeature.columnMetadata }"
                      (featureAttributeChanged)="featureChanged($event)"></tm-edit-form>
      </div>
      <div class="buttons">
        <button mat-flat-button i18n (click)="closeDialog()">
          Close
        </button>
        <button mat-flat-button i18n (click)="save(layerDetails.layer.id, currentFeature)" [disabled]="!updatedAttributes" color="primary">
          Save
        </button>
        <button mat-flat-button i18n (click)="delete(layerDetails.layer.id, currentFeature)" color="primary">
          Delete
        </button>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="selectableFeature$ | async as selectableFeatures">
    <ng-container *ngIf="selectableFeatures.length > 0">
      <div class="content">
        <tm-edit-select-feature [features]="selectableFeatures"></tm-edit-select-feature>
      </div>
      <div class="buttons">
        <button mat-flat-button i18n (click)="closeDialog()">
          Close
        </button>
      </div>
    </ng-container>
  </ng-container>
</tm-dialog>

<tm-map-spinner [coordinates$]="editCoordinates$"
                [loading$]="loadingEditFeatureInfo$"></tm-map-spinner>
<tm-edit-tool></tm-edit-tool>
<tm-edit-geometry-tool (geometryChanged)="geometryChanged($event)"></tm-edit-geometry-tool>
