<ng-template #buttonWithMenuTrigger>
  <button *ngIf="menuTrigger"
          mat-icon-button
          (click)="handleClick()"
          [matMenuTriggerFor]="menuTrigger"
          [tmTooltip]="tooltip$ ? ((tooltip$ | async) ?? '') : (tooltip || '')"
          matTooltipPosition="right"
          [class.button--active]="(active$ | async) ?? false">
    <mat-icon *ngIf="icon" [svgIcon]="icon"></mat-icon>
    <ng-content></ng-content>
  </button>
</ng-template>
<ng-container *ngIf="!menuTrigger; else: buttonWithMenuTrigger">
  <button mat-icon-button
          (click)="handleClick()"
          [tmTooltip]="tooltip$ ? ((tooltip$ | async) ?? '') : (tooltip || '')"
          matTooltipPosition="right"
          [class.button--active]="(active$ | async) ?? false">
    <mat-icon *ngIf="icon" [svgIcon]="icon"></mat-icon>
    <ng-content></ng-content>
  </button>
</ng-container>
