export enum BaseComponentTypeEnum {
  TOC = 'TOC',
  ATTRIBUTE_LIST = 'ATTRIBUTE_LIST',
  DRAWING = 'DRAWING',
  LEGEND = 'LEGEND',
  PRINT = 'PRINT',
  BACKGROUND_LAYER_TOGGLE = 'BACKGROUND_LAYER_TOGGLE',
  FEATURE_INFO = 'FEATURE_INFO',
  COORDINATE_PICKER = 'COORDINATE_PICKER',
  MEASURE = 'MEASURE',
  MOUSE_COORDINATES = 'MOUSE_COORDINATES',
  SCALE_BAR = 'SCALE_BAR',
  SIMPLE_SEARCH = 'SIMPLE_SEARCH',
  ZOOM = 'ZOOM',
  FILTER = 'FILTER',
  GEOLOCATION = 'GEOLOCATION',
  STREETVIEW = 'STREETVIEW',
  EDIT = 'EDIT',
}
