<ng-template #strokeTypeImage let-strokeType>
  <svg viewBox="0 0 100 10" xmlns="http://www.w3.org/2000/svg">
    <line x1="10" y1="5" x2="90" y2="5" stroke-width="1" stroke-linecap="round"
          [attr.stroke-dasharray]="getDashArray(strokeType)"></line>
  </svg>
</ng-template>

<div class="styling-form">

  <div class="point-settings settings-section" *ngIf="showPointSettings()">

    <h2 class="section-label" i18n>
      Symbol
    </h2>

    <div class="form-field form-field__columns">
      <label i18n>Symbol</label>
      <tm-icon-picker [icons]="getMarkers()"
                      [selectedIcon]="getSelectedMarker()"
                      [iconColor]="iconColor"
                      (iconChange)="changeMarker($event)"></tm-icon-picker>
    </div>

    <div class="form-field form-field__columns">
      <label i18n>Fill color</label>
      <tm-color-picker [color]="style.markerFillColor"
                       (colorChange)="changeMarkerFill($event)"></tm-color-picker>
    </div>

    <div class="form-field form-field__columns">
      <label>Line color</label>
      <tm-color-picker [color]="style.markerStrokeColor"
                       (colorChange)="changeMarkerStroke($event)"></tm-color-picker>
    </div>

    <div class="form-field form-field__columns">
      <label i18n>Line thickness</label>
      <span>{{style.markerStrokeWidth}}px</span>
    </div>

    <div class="form-field form-field__slider">
      <tm-slider [max]="10"
                 [min]="1"
                 [step]="1"
                 (valueChange)="changeMarkerStrokeWidth($event)"
                 [value]="style.markerStrokeWidth">
      </tm-slider>
    </div>

    <div class="form-field form-field__columns">
      <label i18n>Size</label>
      <span>{{style.markerSize}}px</span>
    </div>

    <div class="form-field form-field__slider">
      <tm-slider [max]="20"
                 [min]="1"
                 [step]="1"
                 (valueChange)="changeMarkerSize($event)"
                 [value]="style.markerSize">
      </tm-slider>
    </div>

    <div class="form-field form-field__columns">
      <label i18n>Rotation</label>
      <span>{{style.markerRotation}}&deg;</span>
    </div>

    <div class="form-field form-field__slider">
      <tm-slider [max]="360"
                 [min]="0"
                 [step]="1"
                 (valueChange)="changeMarkerRotation($event)"
                 [value]="style.markerRotation">
      </tm-slider>
    </div>

  </div>

  <div class="label-settings settings-section" *ngIf="showLabelSettings()">

    <h2 class="section-label" i18n>
      Label
    </h2>

    <mat-form-field appearance="outline" color="primary">
      <mat-label i18n>Label</mat-label>
      <input matInput *ngIf="!isLabelType()" i18n-placeholder placeholder="Label" [formControl]="labelControl">
      <textarea matInput *ngIf="isLabelType()" i18n-placeholder placeholder="Label" [formControl]="labelControl"></textarea>
      <div matSuffix>
        <button *ngIf="showInsertCoordinates()"
                mat-icon-button
                tmTooltip="Insert coordinates as label"
                i18n-tmTooltip
                (click)="insertCoordinates()">
          <mat-icon svgIcon="coordinates"></mat-icon>
        </button>
        <button *ngIf="showInsertLength()"
                mat-icon-button
                tmTooltip="Insert length as label"
                i18n-tmTooltip
                (click)="insertLength()">
          <mat-icon svgIcon="tools_measure_length_outline"></mat-icon>
        </button>
        <button *ngIf="showInsertArea()"
                mat-icon-button
                tmTooltip="Insert area as label"
                i18n-tmTooltip
                (click)="insertArea()">
          <mat-icon svgIcon="tools_measure_area_outline"></mat-icon>
        </button>
      </div>
    </mat-form-field>

    <div class="form-field form-field__columns form-field__buttons">
      <label i18n>Format</label>
      <mat-button-toggle-group multiple="true">
        <mat-button-toggle (click)="toggleStyle(labelStyleValues.bold)"
                           [checked]="hasLabelStyle(labelStyleValues.bold)">
          <mat-icon svgIcon="style_bold"></mat-icon>
        </mat-button-toggle>
        <mat-button-toggle (click)="toggleStyle(labelStyleValues.italic)"
                           [checked]="hasLabelStyle(labelStyleValues.italic)">
          <mat-icon svgIcon="style_italic"></mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="form-field form-field__columns">
      <label i18n>Size</label>
      <span>{{style.labelSize}}px</span>
    </div>

    <div class="form-field form-field__slider">
      <tm-slider [max]="30"
                 [min]="1"
                 [step]="1"
                 (valueChange)="changeLabelSize($event)"
                 [value]="style.labelSize">
      </tm-slider>
    </div>

    <div class="form-field form-field__columns">
      <label i18n>Color</label>
      <tm-color-picker [color]="style.labelColor"
                       (colorChange)="changeLabelColor($event)"></tm-color-picker>
    </div>

    <div class="form-field form-field__columns">
      <label i18n>Halo</label>
      <tm-color-picker [color]="style.labelOutlineColor"
                       [allowEmptyColor]="true"
                       (colorChange)="changeLabelOutlineColor($event)"></tm-color-picker>
    </div>

    <div class="form-field form-field__columns">
      <label i18n>Rotation</label>
      <span>{{style.labelRotation}}&deg;</span>
    </div>

    <div class="form-field form-field__slider">
      <tm-slider [max]="360"
                 [min]="0"
                 [step]="1"
                 (valueChange)="changeLabelRotation($event)"
                 [value]="style.labelRotation">
      </tm-slider>
    </div>

  </div>

  <div class="line-settings settings-section" *ngIf="showLineSettings()">

    <h2 class="section-label" i18n>
      Line
    </h2>

    <div class="form-field form-field__columns">
      <label i18n>Color</label>
      <tm-color-picker [color]="style.strokeColor"
                       (colorChange)="changeStrokeColor($event)"></tm-color-picker>
    </div>

    <div class="form-field form-field__columns">
      <label i18n>Thickness</label>
      <span>{{style.strokeWidth}}px</span>
    </div>

    <div class="form-field form-field__slider">
      <tm-slider [max]="20"
                 [min]="1"
                 [step]="1"
                 (valueChange)="changeStrokeWidth($event)"
                 [value]="style.strokeWidth">
      </tm-slider>
    </div>

    <div class="form-field form-field__columns">
      <label i18n>Opacity</label>
      <span>{{style.strokeOpacity}}%</span>
    </div>

    <div class="form-field form-field__slider">
      <tm-slider [max]="100"
                 [min]="0"
                 [step]="1"
                 [displayWith]="formatThumb"
                 (valueChange)="changeStrokeOpacity($event)"
                 [value]="style.strokeOpacity">
      </tm-slider>
    </div>

    <mat-form-field appearance="outline" color="primary">
      <mat-label i18n>Stroke type</mat-label>
      <mat-select [formControl]="strokeTypeControl"
                  panelClass="select-line-style"
                  class="select-line-style">
        <mat-select-trigger>
          <ng-container *ngTemplateOutlet="strokeTypeImage; context: { $implicit: style.strokeType }"></ng-container>
        </mat-select-trigger>
        <mat-option *ngFor="let strokeTypeValue of strokeTypeValues" [value]="strokeTypeValue">
          <ng-container *ngTemplateOutlet="strokeTypeImage; context: { $implicit: strokeTypeValue }"></ng-container>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" color="primary" *ngIf="showArrowSetting()">
      <label i18n>Arrow</label>
      <mat-select [formControl]="arrowTypeControl">
        <mat-option *ngFor="let arrowTypeValue of arrowTypeValues"
                    [value]="arrowTypeValue.value">
          {{arrowTypeValue.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <div class="polygon-settings settings-section" *ngIf="showPolygonSettings()">

    <h2 class="section-label" i18n>
      Fill
    </h2>

    <div class="form-field form-field__columns">
      <label i18n>Color</label>
      <tm-color-picker [color]="style.fillColor"
                       (colorChange)="changeFillColor($event)"></tm-color-picker>
    </div>

    <div class="form-field form-field__columns form-field__columns--right-aligned checkbox-field">
      <mat-checkbox i18n
                    [checked]="style.stripedFill"
                    (change)="changeStripedFill($event)">Striped fill</mat-checkbox>
    </div>

    <div class="form-field form-field__columns">
      <label i18n>Opacity</label>
      <span>{{style.fillOpacity}}%</span>
    </div>

    <div class="form-field form-field__slider">
      <tm-slider [max]="100"
                 [min]="0"
                 [step]="1"
                 [displayWith]="formatThumb"
                 [value]="style.fillOpacity"
                 (valueChange)="changeFillOpacity($event)">
      </tm-slider>
    </div>

  </div>

</div>
