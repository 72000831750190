<div class="table-container">

  <div class="table-container-error-message" *ngIf="errorMessage$ | async as errorMessage">
    <tm-error-message [friendlyError]="true" [message]="errorMessage"></tm-error-message>
    <div><button mat-flat-button color="primary" (click)="reloadData()" i18n>Retry</button></div>
  </div>

  <div *ngIf="hasNoRows$ | async" i18n class="table-container-empty-message">No rows found</div>

  <tm-attribute-list-table *ngIf="hasRows$ | async"
                           role="table"
                           [rows]="rows$ | async"
                           [columns]="columns$ | async"
                           [sort]="sort$ | async"
                           [filters]="filters$ | async"
                           [selectedRowId]="selectedRowId$ | async"
                           (selectRow)="onSelectRow($event)"
                           (setSort)="onSortClick($event)"
                           (setFilter)="onSetFilter($event)"></tm-attribute-list-table>

</div>
