<ng-container *ngIf="(componentsConfig$ | async) as config">
  <div class="menubar">
    <tm-menubar></tm-menubar>
  </div>
  <div class="content">
    <div class="center">
      <div class="left">
        <tm-menubar-panel>
          <tm-toc *ngIf="isComponentEnabled(config, componentTypes.TOC)"></tm-toc>
          <tm-legend *ngIf="isComponentEnabled(config, componentTypes.LEGEND)"></tm-legend>
          <tm-drawing *ngIf="isComponentEnabled(config, componentTypes.DRAWING)"></tm-drawing>
          <tm-print *ngIf="isComponentEnabled(config, componentTypes.PRINT)"></tm-print>
          <tm-filter *ngIf="isComponentEnabled(config, componentTypes.FILTER)"></tm-filter>
        </tm-menubar-panel>
      </div>
      <div class="map">
        <tm-feature-info *ngIf="isComponentEnabled(config, componentTypes.FEATURE_INFO)"></tm-feature-info>
        <tm-edit-dialog *ngIf="isComponentEnabled(config, componentTypes.EDIT)"></tm-edit-dialog>
        <tm-map></tm-map>
        <tm-map-controls></tm-map-controls>
        <tm-attribute-list *ngIf="isComponentEnabled(config, componentTypes.ATTRIBUTE_LIST)"></tm-attribute-list>
        <div class="map-controls-left">
          <tm-measure *ngIf="isComponentEnabled(config, componentTypes.MEASURE)"></tm-measure>
          <tm-clicked-coordinates *ngIf="isComponentEnabled(config, componentTypes.COORDINATE_PICKER)"></tm-clicked-coordinates>
          <tm-streetview *ngIf="isComponentEnabled(config, componentTypes.STREETVIEW)"></tm-streetview>
          <tm-simple-search *ngIf="isComponentEnabled(config, componentTypes.SIMPLE_SEARCH)"></tm-simple-search>
          <tm-edit *ngIf="isComponentEnabled(config, componentTypes.EDIT)"></tm-edit>
        </div>
        <div class="map-controls-right">
          <tm-background-layer-toggle *ngIf="isComponentEnabled(config, componentTypes.BACKGROUND_LAYER_TOGGLE)"></tm-background-layer-toggle>
          <div class="map-controls-column">
            <tm-zoom-buttons *ngIf="isComponentEnabled(config, componentTypes.ZOOM)"></tm-zoom-buttons>
            <tm-geolocation *ngIf="isComponentEnabled(config, componentTypes.GEOLOCATION)"></tm-geolocation>
          </div>
        </div>
        <div class="map-controls-left map-controls-left--on-bottom">
          <tm-scale-bar *ngIf="isComponentEnabled(config, componentTypes.SCALE_BAR)"></tm-scale-bar>
        </div>
        <div class="map-controls-right map-controls-right--on-bottom">
          <tm-mouse-coordinates *ngIf="isComponentEnabled(config, componentTypes.MOUSE_COORDINATES)"></tm-mouse-coordinates>
        </div>
      </div>
    </div>
    <div class="bottom"></div>
  </div>
</ng-container>
