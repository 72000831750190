<div class="streetview map-control-button-container">
  <button mat-flat-button
          class="map-control-button toggle-button"
          i18n-tmTooltip
          tmTooltip="Open Streetview"
          [class.toggle-button--active]="toolActive$ | async"
          (click)="toggle()">
    <mat-icon svgIcon="tools_streetview"></mat-icon>
  </button>
</div>
