<h3 mat-dialog-title i18n>Filter on {{getColumnName()}}</h3>
<div (keydown.enter)="onOk()">

  <tm-attribute-filter [attributeType]="getAttributeType()"
                       [filter]="filter"
                       [uniqueValues$]="uniqueValues$"
                       (filterChanged)="updateFilter($event)"></tm-attribute-filter>

  <mat-dialog-actions>

    <button mat-raised-button
            color="primary"
            [disabled]="!isValidFilter()"
            (click)="onOk()" i18n>
      Set
    </button>
    <button mat-raised-button color="primary"
            (click)="onClear()" i18n>
      Clear
    </button>
    <button mat-raised-button color="primary"
            (click)="onCancel()" i18n>
      Cancel
    </button>
  </mat-dialog-actions>

</div>
