<div *ngIf="node"
     class="tree-node"
     [class.out-of-scale]="isLayerOutOfScale()"
     [class.level]="isLevel()">
  <div class="tree-node__label">
    <span class="tree-node__label-content">{{node.label}}</span>
  </div>
  <div class="tree-node__actions">
    <button *ngIf="isLayer()"
            mat-icon-button
            (click)="showInfo($event, node.id)"><mat-icon svgIcon="info"></mat-icon></button>
  </div>
</div>
