<tm-dialog [open]="dialogOpen$ | async"
           [openFromRight]="true"
           [widthMargin]="panelWidthMargin"
           class="feature-info-dialog"
           (closeDialog)="closeDialog()"
           [allowCollapse]="true"
           [collapsed]="dialogCollapsed$ | async"
           (expandCollapseDialog)="expandCollapseDialog()"
           dialogTitle="Object Information"
           i18n-dialogTitle>
  <div class="feature-info" *ngIf="currentFeature$ | async as currentFeature">
    <div class="content">
      <h2>{{currentFeature.layer.title}}</h2>
      <div *ngFor="let att of currentFeature.sortedAttributes" class="row">
        <strong>{{att.label}}</strong>
        <div [innerHTML]="att.attributeValue | htmlify"></div>
      </div>
    </div>
    <div class="buttons">
      <button mat-flat-button i18n (click)="back()" color="primary" [disabled]="isBackDisabled()">
        Back
      </button>
      <button mat-flat-button i18n (click)="next()" color="primary" [disabled]="isNextDisabled()">
        Next
      </button>
    </div>
  </div>
</tm-dialog>
