<div class="measure-buttons map-control-button-container">
  <button mat-flat-button
          class="measure-length toggle-button map-control-button"
          i18n-tmTooltip
          tmTooltip="Measure length"
          [class.toggle-button--active]="toolActive === 'length'"
          (click)="measure('length')">
    <mat-icon svgIcon="tools_measure_length"></mat-icon>
  </button>
  <button mat-flat-button
          class="measure-area toggle-button map-control-button"
          i18n-tmTooltip
          tmTooltip="Measure area"
          [class.toggle-button--active]="toolActive === 'area'"
          (click)="measure('area')">
    <mat-icon svgIcon="tools_measure_area"></mat-icon>
  </button>
</div>
