<mat-button-toggle-group [class.hide-on-small-screens]="!!small_screens_icon">
  <mat-button-toggle (click)="cycleNextOption()">
    {{getLabel()}}
  </mat-button-toggle>
  <mat-button-toggle class="drop-down-button" [matMenuTriggerFor]="dropdownMenu">
    <mat-icon svgIcon="drop_down"></mat-icon>
  </mat-button-toggle>
</mat-button-toggle-group>

<div class="map-control-button-container" *ngIf="small_screens_icon">
  <button mat-flat-button
          i18n-tmTooltip
          class="map-control-button"
          tmTooltip="Background layer selection"
          [matMenuTriggerFor]="dropdownMenu">
    <mat-icon [svgIcon]="small_screens_icon"></mat-icon>
  </button>
</div>

<mat-menu #dropdownMenu="matMenu">
  <button mat-menu-item
          class="split-button-menu-item"
          *ngFor="let option of optionsList"
          (click)="selectOption(option.id)">
    <mat-icon svgIcon="check" *ngIf="option.id === selectedOptionId"></mat-icon>
    {{ option.label }}
  </button>
</mat-menu>
