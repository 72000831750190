<ng-template #treeNode let-node>
  <tm-toc-node-layer [node]="node" [scale]="scale" (showTreeNodeInfo)="showTreeNodeInfo($event)"></tm-toc-node-layer>
</ng-template>

<div class="toc" *ngIf="visible$ | async">
  <div class="toc-header">
    <div class="toc-header-left">
      <tm-toggle-all-layers-button></tm-toggle-all-layers-button>
      <button mat-icon-button
              color="primary"
              i18n-tmTooltip
              class="map-control-button"
              tmTooltip="Filter layers"
              (click)="toggleLayerFilter()">
        <mat-icon svgIcon="search"></mat-icon>
      </button>
    </div>

    <div class="toc-header-right">
      <button *ngIf="isMobileDevice"
              mat-icon-button
              matTooltip="Re-order layers"
              color="primary"
              [class.button--active]="dragDropEnabled"
              (click)="toggleReordering()">
        <mat-icon svgIcon="reorder"></mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="filterEnabled$ | async" class="toc-search">
    <tm-toc-filter-input></tm-toc-filter-input>
  </div>
  <tm-tree class="toc-tree"
           [treeNodeTemplate]="treeNode"
           [expandOnGroupClick]="true"
           [getDropZones]="getDropZoneConfig()"></tm-tree>
  <tm-toc-node-details *ngIf="infoTreeNodeId$ | async as selectedLayerDetail"
                    [nodeId]="selectedLayerDetail"
                    (closeDetails)="layerInfoClosed()"></tm-toc-node-details>
</div>
