<div class="menubar">
  <div class="menubar-section">
    <tm-menubar-logo></tm-menubar-logo>
  </div>
  <div class="divider"></div>
  <div class="menubar-section menu-buttons">
    <ng-template #menuButtonsContainer></ng-template>
  </div>
  <div class="divider"></div>
  <div class="menubar-section">
    <tm-profile></tm-profile>
  </div>
</div>
