<div *ngIf="open"
     [@inOutAnimation]="{ value: true, params: { translate: openFromRight ? actualWidth + 'px' : (-1 * actualWidth) + 'px' }}"
     class="dialog mat-elevation-z4"
     [style.width]="actualWidth + 'px'"
     [class.dialog--hidden]="hidden"
     [class.dialog--collapsed]="collapsed">

  <div class="dialog-header">
    <h1>{{dialogTitle || ''}}</h1>
    <button *ngIf="allowCollapse" mat-icon-button class="toggle-button" (click)="expandCollapse()">
      <mat-icon *ngIf="!collapsed" svgIcon="chevron_top"></mat-icon>
      <mat-icon *ngIf="collapsed" svgIcon="chevron_bottom"></mat-icon>
    </button>
    <button mat-icon-button class="close-button" (click)="close()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>

  <div class="dialog-content">
    <ng-content></ng-content>
  </div>

</div>
